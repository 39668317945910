//-----------------------
$primary-black: #1c1c1c;

//--------------------
$primary-white: #ffffff;

// company color codes
$primary-blue: #0480FC;
$secondary-blue: #075CB2;
$primary-grey: #76787A;
$primary-ash: #D3D3D3;
