.forgot-wrapper{
    display: flex;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.forgot-password{
    width: 600px;
    max-width: 600px;
    height: 70vh;
    overflow-y: auto;
    background-color: $primary-white;
    border-radius: 16px;
    box-shadow: 0rem 0.5rem 0.7rem #00000026;
    
    @media screen and (max-width: 600px) {
        width: 300px;
    }
}

.forgot-wrap{
    width: 70%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: inherit;
}