*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

:root {
  font-size: 16px;
}

@font-face {
  font-family: 'Trav4College';
  src: url(../../asset/font/t4c-font/SamoSansPro-Light.ttf);
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Trav4College';
  src: url(../../asset/font/t4c-font/SamoSansPro-Medium.ttf);
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Trav4College';
  src: url(../../asset/font/t4c-font/SamoSansPro-Bold.ttf);
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Trav4College';
  src: url(../../asset/font/t4c-font/SamoSansPro-Black.ttf);
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Trav4College';
  src: url(../../asset/font/t4c-font/SamoSansPro-UltraBlack.ttf);
  font-weight: 900;
  font-style: normal;
}

html {
  body {
    box-sizing: border-box;
    font-family: 'Trav4College', sans-serif;
  }

  a {
    &:hover {
      text-decoration: none;
    }
  }
}

// root configuraion for screen
@media screen and (max-width: 768px) {
  :root {
    font-size: 14px;
  }
}

@media screen and (max-width: 500px) {
  :root {
    font-size: 12px;
  }
}
