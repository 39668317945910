.signup-wrapper{
    display: flex;
    height: 100vh;
    
    & > div{
        height: inherit;
    }
}

.signup-banner{
    background-image: url(../../asset/img/signup-bg.png);
    background-repeat: no-repeat; 
    width: 45%; 
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    background-size: cover;
    
    .signup-banner--logo{
        position: absolute;
        top: 0;
        width: 100%;
        img{
            margin-top: 4rem;
        }
    }
    
    .signup-content{
        margin-top: 14rem;
        
        h1{
            color: $primary-white;
            font-weight: 700;
            font-size: 3rem;
        }
        
        p{
            font-size: 1.5rem;
        }
    }
    
    @media screen  and (max-width: 600px) {
        display: none
    }
}

.signup-form{
    width: 55%;
    overflow-y: auto;
    
    @media screen and (max-width: 600px) {
      width: 100%;
    }
}

.signup-desc{
    margin-top: 5rem;
    width: 65%;
    margin-left: 5rem;
    height: inherit;
    margin-bottom: 3rem;
    
    h2{
        font-size: 2rem;
        font-weight: 700;
        color: $primary-blue;
    }
    
    @media screen and (max-width: 600px){
        margin-left: auto;
        margin-right: auto;
        width: 90%;
    }
}

.signup-category{
    margin-top: 2rem;
    display: flex;
    gap: 2rem;
     & > div{
         width: calc(100% - (2 * 2rem));
     }
    
    &--card{
        height: 100px;
        // width: 145px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $primary-ash;
        border: 1px solid $primary-ash;
        transition: 0.4s ease-in;
        cursor: pointer;
    }
    
    &--active{
        border: 1px dashed $primary-blue;
        color: $primary-blue;
    }
}

.signup-forms{
    margin-top: 2rem;
    
    input{
        border: 1px solid $primary-ash;
    }
}

.password-icon{
    position: absolute;
    top: 30%;
    right: 2rem;
}