//colors helpers
.white {
  color: $primary-white !important;
}

.blue {
  color: $primary-blue !important;
}

.sec-blue {
  color: $secondary-blue !important;
}

//font weight helpers
.w-light {
  font-weight: 500;
}

.w-bold {
  font-weight: 700;
}

.w-ebold {
  font-weight: 800;
}

//spacing helpers
.m-t2 {
  margin-top: 2rem;
}

//font size helpers
.xs-font {
  font-size: 0.875rem !important;
}

.sm-font{
  font-size: 1rem !important;
}

.xmd-font{
  font-size: 1.5rem;
}

.md-font{
  font-size: 2rem;
}

//input size helpers
.inputs{
    padding: 1.8rem 1.2rem !important;
    border-radius: 8px !important;
}

.basic-single{
  margin-top: 1.5rem !important;
}

.select__control{
  padding: 1rem 1.2rem !important;
  border-radius: 8px !important;
}

//brand logo size helpers
.logo-brand{
  width: 190px;
}
