.login-wrap{
    height: 100vh;
    display: flex;
    & > div {
        width: 50%;
        height: inherit;
    }
    
    .right-pane{
        background-image: url('../../asset/img/login-bg.png');
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;
        
        h1{
            margin-top: 10rem;
            font-size: 3rem;
        }
        
        @media screen and (max-width: 600px) {
            display: none;
        }
    }
    
    .left-pane{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        .login-section{
            margin-top: 2.5rem;
            width: 70%;
        }
        
        @media screen and (max-width: 600px) {
            width: 100%;
        }
    }
}

.login-brand{
    width: 190px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.login-desc{
    margin-top: 2.5rem;
    
    h2{
        font-size: 2rem;
    }
}

.login-form{
    margin-top: 2.5rem;
    
    input{
        padding: 2rem 1.2rem;
        border-radius: 8px;
        &::placeholder{
            color: $primary-black;
        }
    }
    
    button{
        margin-top: 2.6rem;
    }
}